import React from "react";

import { Card, Row, Col } from "reactstrap";

export default function AdvancedCard({ textButton, modal, icon, title, text }) {
  return (
    <Card className="my-2">
      <Row className="p-3">
        <Col
          className="d-none d-md-block justify-content-center align-items-center"
          md={1}
        >
          <i className={icon} />
        </Col>
        <Col className="ml-2" xs={10} md={8}>
          <h5>{title}</h5>
          <p>{text}</p>
        </Col>
        <Col className="text-right" md={2}>
          <button className="button-like-link" onClick={modal}>
            {textButton}
          </button>
        </Col>
      </Row>
    </Card>
  );
}
