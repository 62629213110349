import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import {
  Button,
  Table,
} from "reactstrap";

import "./styles.scss"
import errorRequest from "common/errorRequest"
import api from 'config/api';
import Page from "components/Page";
import CustomPagination from 'components/CustomPagination';

export default function Combos() {
  const [loading, setLoading] = useState(false);
  const [combos, setCombos] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const limit = 5;

  useEffect(() => {
    fetchCombos();
  }, [page]);

  async function fetchCombos() {
    setLoading(true);
    try {
      const { data } = await api.get(`/combo?limit=${limit}&offset=${page - 1}`);

      setCombos(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error)
    }
    setLoading(false);
  }

  async function deleteCombo(idCombo) {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realmente excluir esse Combo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, exclua ele!'
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          await api.delete(`/combo/${idCombo}`)
          Swal.fire(
            'Deletado!',
            'O combo foi excluido com sucesso',
            'success'
          )
          setCombos(combos.filter(x => x.idCombo !== idCombo))
        } catch (error) {
          errorRequest(history, error)
        }
        setLoading(false);
      }
    })
  }

  return (
    <Page title="Lista de combos" loading={loading}>
      <>
        <Button
          className="float-md-right my-2"
          color="success"
          onClick={() => history.push("/admin/combo-detail")}
        >
          Novo Combo
      </Button>
        <Table className="table-combos mt-3" responsive>
          <thead>
            <tr>
              <th>Imagem</th>
              <th>Nome</th>
              <th>Editar</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            {(!combos || combos.length === 0) &&
              <tr><td className="text-center" colSpan="4">Nenhum registro foi encontrado...</td></tr>
            }
            {combos && combos.length > 0 && combos.map((combo) => (
              <tr key={combo.idCombo} >
                <td>
                  <img
                    alt="imagem do combo"
                    className="image-thumb"
                    src={combo.urlImageCombo}
                  />
                </td>
                <td>{combo.name}</td>
                <td>
                  <button
                    className="button-like-link"
                    onClick={() => history.push(`/admin/combo-detail?idCombo=${combo.idCombo}`)}
                  >
                    <i className="fas fa-pencil-alt"></i> Editar
                          </button>
                </td>
                <td>
                  <button className="button-like-link" onClick={() => deleteCombo(combo.idCombo)}>
                    <i className="far fa-trash-alt" /> Apagar
                          </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {total > limit && (
          <div className="d-flex my-3 justify-content-center">
            <CustomPagination
              total={total}
              page={page}
              length={combos.length}
              limit={limit}
              setPage={setPage}
            />
          </div>
        )}
      </>
    </Page>
  );
}
