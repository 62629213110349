import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Label, Button } from "reactstrap";
import { ComboDetailContext } from "../../index";

import NuvemShopProductList from "./NuvemShopProductList/index";
import ProductListCombos from "./ProductListCombos/index";

import api from "config/api";
import errorRequest from "common/errorRequest";
import SearchBar from "components/SearchBar";
import "./styles.scss"

export default function ProductsCombos() {
  const [search, setSearch] = useState("");
  const {
    setLoading,
    nuvemShopProducts, setNuvemShopProducts,
    comboProducts, setComboProducts,
    setVariants,
    history,
    idCombo,
  } = useContext(ComboDetailContext);

  useEffect(() => {
    fetchNuvemShopProducts();
  }, []);

  useEffect(() => {
    if (!idCombo) {
      if (!comboProducts || comboProducts.length == 0) {
        setVariants([]);
        return;
      }

      const variationsProducts = comboProducts.map(x => x.variants.map(y => y.values[0].pt));
      const f = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))));
      const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);
      const combinations = cartesian(...variationsProducts);
      let variations = [];

      combinations.forEach(combination => {
        const values = Array.isArray(combination) ?
          combination.map(x => { return { pt: x } }) : [{ pt: combination }];

        variations.push({ stock: 1, values, })
      });

      setVariants(variations);
    }
  }, [comboProducts])

  async function fetchNuvemShopProducts() {
    setLoading(true);
    try {
      const params = { search };
      const { data } = await api.get(`/nuvemShopProduct`, { params });
      setNuvemShopProducts(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function sendProducts() {
    setComboProducts([...comboProducts, ...nuvemShopProducts.filter(x => x.checked)]);
    nuvemShopProducts.filter(x => x.checked).forEach(nuvemShopProduct => {
      nuvemShopProduct.checked = false;
    });

    setNuvemShopProducts(nuvemShopProducts => nuvemShopProducts);
    const cbs = document.querySelectorAll('input.product-nuvem-shop.form-check-input');
    cbs.forEach((cb) => {
      cb.checked = false;
    });
  }

  function removeProducts() {
    setComboProducts(comboProducts => comboProducts.filter(x => !x.checked));
  }

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Row>
            <Col className="productListTop">
              <SearchBar
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClick={() => fetchNuvemShopProducts()}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    fetchNuvemShopProducts();
                  }
                }}
                disabled={!!idCombo}
              />
              <Button
                onClick={() => sendProducts()}
                color="success"
                className="ml-3"
                disabled={!!idCombo}
              >
                Adicionar »
              </Button>
            </Col>
            <Col className="productListTop">
              <Row className="productListTopRow">
                <Label className="noMargin help-title mr-3">
                  Produtos do Combo
                </Label>
                <Button
                  color="danger"
                  onClick={() => removeProducts()}
                  disabled={!!idCombo}
                >
                  « Remover seleção
                </Button>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="productList mt-1">
              {
                nuvemShopProducts &&
                  nuvemShopProducts.length > 0 ?
                  nuvemShopProducts.map((nuvemShopProduct) => (
                    <NuvemShopProductList
                      className="productListStriped"
                      nuvemShopProduct={nuvemShopProduct}
                      key={nuvemShopProduct.id + "-products"}
                    />
                  )) :
                  <Label>Não foram encontrados produtos...</Label>
              }
            </Col>
            <Col className="productList mt-1">
              {
                comboProducts &&
                  comboProducts.length ?
                  comboProducts.map((comboProduct) => (
                    <ProductListCombos
                      className="productListStriped"
                      key={comboProduct.id + "-combo-products"}
                      comboProduct={comboProduct}
                    />
                  )) :
                  <Label className="m-3">Ainda não foram adicionados produtos nesta campanha...</Label>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
