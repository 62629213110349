import React, { useContext } from 'react';
import Swal from 'sweetalert2';

import { Row, Col, Input, Label } from 'reactstrap';

import { ComboDetailContext } from "../../index";

export default function Variation({ variant }) {

    const { variants } = useContext(ComboDetailContext);

    function changeStock(item, stock) {
        Swal.fire({
            title: 'Atenção',
            text: 'Deseja alterar o estoque de todas as outras variações?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.value) {
                for (let i = 0; i < variants.length; i++) {
                    variants[i].stock = stock;
                    variants[i].edited = true;
                }
                const stocks = document.getElementsByClassName("stock-variations")
                for (let i = 0; i < stocks.length; i++) {
                    stocks[i].value = stock;
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                item.stock = stock;
                item.edited = true;
            }
        })
    }

    return (
        <Row className="input-row">
            <Col>
                <Label>Variação</Label>
            </Col>
            <Col>
                <span>{variant.values.map(x => x.pt).join(" / ")}</span>
            </Col>
            <Col>
                <Label>Estoque</Label>
                <Input
                    className="stock-variations"
                    onChange={(e) => changeStock(variant, e.target.value)}
                    defaultValue={variant.stock}
                    type="number"
                    min="1"
                />
            </Col>
        </Row>
    );
}
