import React, { useContext } from 'react';
import filesize from 'filesize';
import { uniqueId } from 'lodash';
import Swal from 'sweetalert2';

import { Modal, Button } from 'reactstrap';
import Upload from 'components/Upload';
import FileList from 'components/FileList';

import { ComboDetailContext } from "../../index";

export default function PicturesModal() {

  const {
    picturesModal, setPicturesModal,
    images, setImages, setDeletedImages,
  } = useContext(ComboDetailContext);

  async function handleDelete(id) {
    if(images.find((image) => image.id !== id && image.saved))
      setDeletedImages(deletedImages => [ ...deletedImages, id]);
    setImages(images.filter((image) => image.id !== id));
  }

  // Adiciona imagens ao array
  function addImage(files) {
    const total = images.length;
    if (total > 4) {
      Swal.fire('Atenção', 'O limite é de 4 imagens por Combo', 'info');
      return;
    }

    let image = files.map((file) => ({
      id: parseInt(uniqueId()),
      file,
      name: file.name,
      src: URL.createObjectURL(file),
      readableSize: filesize(file.size),
    }));
    setImages(image.concat(images));
  }

  return (
    <Modal
      size="md"
      className="more-options-modal"
      toggle={() => setPicturesModal(false)}
      isOpen={picturesModal}
    >
      <div className="modal-header">
        <h3 className="modal-title">Fotos do Produto</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setPicturesModal(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Upload onUpload={addImage} />
        {!!images.length && (
          <FileList files={images} onDelete={handleDelete} />
        )}
        <Button
          onClick={() => setPicturesModal(false)}
          color="primary"
          className="mt-3 float-right"
        >
          Pronto
        </Button>
      </div>
    </Modal>
  );
}
