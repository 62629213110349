import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import Page from "components/Page";
import Detail from "./Detail/index";
import CategoryModal from "./Detail/CategoryModal/index";
import MoreOptionsModal from "./Detail/MoreOptionsModal/index";
import PicturesModal from "./Detail/PicturesModal/index";

const ComboDetailContext = React.createContext([{}, () => {}]);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ComboDetail() {
  const query = useQuery();
  const idCombo = query.get("idCombo");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [brand, setBrand] = useState('');
  const [weight, setWeight] = useState('');
  const [depth, setDepth] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [stock, setStock] = useState(1);
  const [editorState, setEditorState] = useState(EditorState.createEmpty);
  const [variants, setVariants] = useState([]);
  const [categoryModal, setCategoryModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [nuvemShopProducts, setNuvemShopProducts] = useState([]);
  const [comboProducts, setComboProducts] = useState([]);
  const [images, setImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [published, setPublished] = useState(true);
  const [moreOptionsModal, setMoreOptionsModal] = useState(false);
  const [picturesModal, setPicturesModal] = useState(false);

  function convertDescription(description, setEditor) {
    if (!description)
      return;

    const convertedDescription = convertFromHTML(description);
    const state = ContentState.createFromBlockArray(
      convertedDescription.contentBlocks,
      convertedDescription.entityMap
    );

    setEditor(EditorState.createWithContent(state));
  }

  return (
    <ComboDetailContext.Provider
      value={{
        name, setName,
        description, setDescription,
        price, setPrice,
        brand, setBrand,
        weight, setWeight,
        depth, setDepth,
        width, setWidth,
        height, setHeight,
        stock, setStock,
        editorState, setEditorState,
        variants, setVariants,
        categoryModal, setCategoryModal,
        categories, setCategories,
        seoTitle, setSeoTitle,
        tags, setTags,
        seoDescription, setSeoDescription,
        published, setPublished,
        moreOptionsModal, setMoreOptionsModal,
        picturesModal, setPicturesModal,
        nuvemShopProducts, setNuvemShopProducts,
        comboProducts, setComboProducts,
        images, setImages,
        deletedImages, setDeletedImages,
        setLoading,
        idCombo,
        history,
        convertDescription,
      }}
    >
      <Page title="Detalhes do combo" loading={loading} back={true} history={history}>
        <>
          <PicturesModal/>
          <CategoryModal/>
          <MoreOptionsModal/>
        </>
        <>
          <Detail />
        </>
      </Page>
    </ComboDetailContext.Provider>
  );
}

export { ComboDetailContext, ComboDetail };