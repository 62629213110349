import React, { useEffect, useContext } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { ComboDetailContext } from "../../index";

export default function Description() {
  const {
    editorState,
    setEditorState,
    setDescription,
  } = useContext(ComboDetailContext);

  useEffect(() => {
    if (setDescription) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const hashtagConfig = { trigger: '#' };
      const directional = false;

      const markup = draftToHtml(rawContentState, hashtagConfig, directional);
      setDescription(markup);
    }
  }, [editorState]);

  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(editorState) => setEditorState(editorState)}
        toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'] }}
      />
    </>
  );
}
