import { ComboDetail } from "views/admin/ComboDetail";
import Combos from "views/admin/Combos";
import UserProfile from "views/admin/UserProfile";
import NotFound from "views/admin/NotFound";

var routes = [
  {
    path: "/combo-detail",
    invisible: true,
    component: ComboDetail,
    layout: "/admin",
  },
  {
    path: "/combo",
    name: "Combos",
    component: Combos,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Perfil",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
