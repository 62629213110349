import React, { useContext, useState } from "react";
import { Row } from "reactstrap";

import { ComboDetailContext } from "../../../index";

export default function NuvemShopProductList({ nuvemShopProduct }) {
  const { nuvemShopProducts, comboProducts, idCombo } = useContext(ComboDetailContext);
  const [checked, setChecked] = useState(false);
  const id = nuvemShopProduct.id;
  const name = nuvemShopProduct.name.pt;
  const variation = nuvemShopProduct.variants[0];
  const productImg = nuvemShopProduct.images.length ? nuvemShopProduct.images[0].src : require("assets/img/no-image.png");

  function addProduct() {
    if(!comboProducts.find(x => x.id === id)){
      if(checked){
        nuvemShopProducts.find(x => x.id === id).checked = false;
        setChecked(false);
      }else{
        nuvemShopProducts.find(x => x.id === id).checked = true;
        setChecked(true);
      }
    }else{
      document.getElementById(`${id}-product`).checked = false;
    }
  }

  return (
    <div className="form-check">
      <input
        className="form-check-input product-nuvem-shop"
        type="checkbox"
        defaultChecked={checked}
        id={`${id}-product`}
        onClick={() => addProduct()}
        disabled={!!idCombo}
      />
      <label className="form-check-label" htmlFor={`${id}-product-nuvem-shop`}>
        <Row className="infoProductsRow">
          <img src={productImg} alt={`foto demonstrativa do produto ${name}`} />
          <div className="productInfo">
            <p className="productName">{name}</p>
            <div className="productPrice">
              <p>Preço original: <span>R$ {variation.price}</span></p>
              {variation.promotional_price && <p>Preço promocional: <span>R$ {variation.promotional_price}</span></p>}
            </div>
          </div>
        </Row>
      </label>
    </div>
  );
}
