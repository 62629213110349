import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import 'react-image-lightbox/style.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import PrivateRoute from 'config/privateRoute';

import AdminLayout from "layouts/Admin.jsx";
import Login from "views/authenticate/Login";
import ForgotPassword from "views/authenticate/ForgotPassword";
import ResetPassword from './views/authenticate/ResetPassword';
import NotFound from './views/admin/NotFound';

const App = () => {
  moment.locale('pt-br');
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={(props) => <Login {...props} />} />
        <Route
          path="/forgot-password"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          path="/reset-password/:token/:email"
          component={(props) => <ResetPassword {...props} />}
        />
        <PrivateRoute
          path="/admin"
          component={(props) => <AdminLayout {...props} />}
        />
        <Route
          exact
          path="*"
          render={(props) => <NotFound {...props} />}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
